import React, { useEffect, useState } from 'react';
import Image from 'next/image';

import Checkers from '../helpers/class/Checkers';
import Consts from '../helpers/class/Consts';

import { FeaturedImagesDesktop } from './jumbotron/FeaturedImagesDesktop';
import { FeaturedImagesMobile } from './jumbotron/FeaturedImagesMobile';
import Motto from './jumbotron/Motto';
const ModalPropertyImages = dynamic( () => import( './templates/property/partials/modals/ModalPropertyImages' ), { ssr: false });
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import CustomModal from './CustomModal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faGrid } from '@fortawesome/pro-solid-svg-icons'
import {GridImagesDesktop} from "./jumbotron/GridImagesDesktop";

export default function Jumbotron ( props ) {

  const pageTitle = props.hasOwnProperty('pageTitle') ? props.pageTitle : ''

  const fullHeight = props.propertyJumbotronType == Consts.JumbotronGridImage ? false : true

  const isDesktop = useSelector(state => state['appSettings']['isDesktop']);

  const [modalState, setModalState] = useState("close");

  const videoUrl = props.details!=undefined && props.details.hasOwnProperty('video_url') ? props.details.video_url : null

  const handleShow = (state) => {
    setModalState(state);
  }

  const handleHide = () => {
    setModalState("close");
  }

  let jumbotronHeight = null;
  let jumbotronOverlayOpacity = null;

  // props.video = videoUrl;
  const property = props.hasOwnProperty('property') && props.property;

  if (!property) {
    if (props.sliderThumbnails)
      if (props.jumbotronHeight > 0)
        jumbotronHeight = props.jumbotronHeight + 'vh';
      else
        jumbotronHeight = '85vh';
    else if (props.jumbotronHeight > 0)
      jumbotronHeight = props.jumbotronHeight + 'vh';
    else
      jumbotronHeight = '85vh';

    if (props.jumbotronOverlayOpacity >= 0)
      jumbotronOverlayOpacity = props.jumbotronOverlayOpacity / 100;
    else
      jumbotronOverlayOpacity = '0.3';
  } else {
    if (props.propertyJumbotronHeight > 0)
      jumbotronHeight = props.propertyJumbotronHeight + 'vh';
    else
      jumbotronHeight = '85vh';

    if (props.propertyJumbotronOverlayOpacity >= 0)
      jumbotronOverlayOpacity = props.propertyJumbotronOverlayOpacity / 100;
    else
      jumbotronOverlayOpacity = '0.3';
  }

  let jumbotronClass = null;
  // if ( props.sliderSearchBar ) {
  //   jumbotronClass = 'jumbotron bg-master-light overflow-visible';
  // } else {
  //   jumbotronClass = 'jumbotron bg-master-light';
  // }
  // if ( props.jumbotronClass ) {
  //   jumbotronClass += `\ ${props.jumbotronClass}`;
  // }

  const image = Checkers.isValidArray(props.album) ? props.album[0].image : null;

  const [jumbotronHeightConst, setJumbotronHeightConst] = useState(jumbotronHeight)

  useEffect(() => {
    if (fullHeight) {
      if (Checkers.objChildExists(props, 'propertyJumbotronType') && !isDesktop) {
        setJumbotronHeightConst('75vw')
      } else {
        setJumbotronHeightConst(jumbotronHeight)
      }
    } else {
      setJumbotronHeightConst('auto')
    }
  }, [isDesktop])

  if (Checkers.objChildExists(props, 'children') && props.children) {
    let height = '500px';
    let jumbotronClass = 'jumbotron\ ';
    if (Checkers.isValidString(props.jumbotronClass)) {
      jumbotronClass += props.jumbotronClass;
    }
    if (Checkers.isValidNumber(props.jumbotronHeight)) {
      height = props.jumbotronHeight + 'vh';
    }
    return (
        <section className={jumbotronClass} style={{height: height}}>
          <>
            {props.children}
          </>
        </section>
    )
  }

  const openPropertiesImageModal = () => {
    if (Checkers.objChildExists(props, 'propertyJumbotronType') && props.propertyJumbotronType == Consts.JumbotronStaticImage) {

    }
  }

  const jumbotronContentHandler = () => {
    return (
        <>
          {
            props.album !== undefined ?
                props.album !== null ?
                    props.video != null ?
                        <>
                          {
                            props.isDesktop ?
                                <video
                                    id="my-player"
                                    className="video-js"
                                    loop
                                    muted={true}
                                    autoPlay={true}
                                    preload={'auto'}
                                    poster={props.album[0].jpg}
                                    style={
                                      {
                                        backgroundColor: 'rgb(0, 0, 0)',
                                        position: 'absolute',
                                        width: '100%',
                                        height: jumbotronHeightConst,
                                        objectFit: 'cover',
                                        objectPosition: 'center'
                                      }
                                    }
                                    data-setup={'{}'}
                                >
                                  <source src={props.video}/>
                                </video>
                                : ''
                          }
                          {
                              image != null &&
                              <>
                                <div className={props.sliderSearchBar ? 'swiper overflow-visible' : 'swiper'}>
                                  <div className={'swiper-wrapper'}>
                                    <div className={'swiper-slide'}>
                                      <Image
                                          priority={true}
                                          className="visible-xs visible-sm"
                                          layout={'fill'}
                                          // sizes={'75vw'}
                                          quality={'100'}
                                          src={image}
                                          alt={'Jumbotron Img test-2'}
                                          placeholder={'blur'}
                                          blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                      />
                                      <Motto
                                          isDesktop={props.isDesktop}
                                          isManager={props.isManager}
                                          hideMotto={true}
                                          designMode={props.designMode}
                                          // searchbarThemeColor={props.app.searchbarThemeColor}
                                          rgbaDesignColor={props.rgbaDesignColor}
                                          sliderSearchBar={props.sliderSearchBar}
                                          searchbarRadius={props.searchbarRadius}
                                          searchbarShadow={props.searchbarShadow}
                                          bookingSearchbarWithBedrooms={props.bookingSearchbarWithBedrooms}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                          }

                        </>
                        : props.album.length == 1 || props.sliderSearchBar || (Checkers.objChildExists(props, 'propertyJumbotronType') && props.propertyJumbotronType == Consts.JumbotronStaticImage) ?
                            image != null && Checkers.isValidString(image) &&
                            <>
                              <div className={props.sliderSearchBar ? 'swiper overflow-visible' : 'swiper'}>
                                <div className={'swiper-wrapper'}>
                                  <div className={'swiper-slide'}>
                                    <img
                                        // priority={true}
                                        // layout={'fill'}
                                        // sizes={'100vw'}
                                        // quality={'100'}
                                        src={image}
                                        alt={'Jumbotron Img test-1'}
                                        placeholder={'blur'}
                                        className={'h-100 w-100'}
                                        // blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                    />
                                    {
                                        props.withMotto &&
                                        <>
                                          <Motto
                                              isDesktop={props.isDesktop}
                                              isManager={props.isManager}
                                              motto={props.content.motto}
                                              logoDecoration={props.logoDecoration}
                                              logoDecorationOriginWidth={props.logoDecorationOriginWidth}
                                              logoDecorationOriginHeight={props.logoDecorationOriginHeight}
                                              jumbotronTextAlign={props.jumbotronTextAlign}
                                              jumbotronTextColor={props.jumbotronTextColor}
                                              sliderTitleShadow={props.sliderTitleShadow}
                                              sliderTitleBold={props.sliderTitleBold}
                                              homeTitle={props.homeTitle}
                                              homeSubtitle={props.homeSubtitle}
                                              // hideMotto={props.sliderSearchBar}
                                              sliderSearchBar={props.sliderSearchBar}
                                              rgbaDesignColor={props.rgbaDesignColor}
                                              searchbarRadius={props.searchbarRadius}
                                              searchbarShadow={props.searchbarShadow}
                                              designMode={props.designMode}
                                              searchbarThemeColor={props.searchbarThemeColor}
                                              bookingSearchbarWithBedrooms={props.bookingSearchbarWithBedrooms}
                                          />
                                          {
                                              jumbotronOverlayOpacity > 0 &&
                                              <div className="bg-overlay" style={{
                                                backgroundColor: 'rgb(0, 0, 0)',
                                                opacity: jumbotronOverlayOpacity
                                              }}/>
                                          }

                                        </>
                                    }

                                  </div>
                                </div>
                              </div>
                              <div className={'container'}>
                                <div className={'container position-relative d-flex justify-content-end'}>
                                  {
                                      (Checkers.objChildExists(props, 'propertyJumbotronType') && props.propertyJumbotronType == Consts.JumbotronStaticImage) &&
                                      <div className="position-absolute z-index-2 pb-5" style={{bottom: 0, right: 0}}>
                                        <button id={'jumbotron_show_modal'} type="button"
                                                className="btn btn-cons btn-lg text-master no-margin"
                                                onClick={() => handleShow('property_photos_modal')}
                                        >
                                          <FontAwesomeIcon icon={faGrid} className={'fs-18 m-r-10'}/>Show photos
                                        </button>
                                        <CustomModal
                                            modalName={'property_photos_modal'}
                                            className="slide_right"
                                            modalSize="lg"
                                            closeModal={() => {
                                              handleHide()
                                            }}
                                            show={modalState === 'property_photos_modal'}
                                        >
                                          <ModalPropertyImages all_images={props.album} handleHide={handleHide}/>
                                        </CustomModal>
                                        {
                                          props.album.map((media, index) => {
                                            if (index > 0) {
                                              return <div key={media.image_id.toString()}
                                                          className="hidden"
                                                          data-fancybox="jumbotron-images"
                                                          data-src={media.image}
                                                          data-caption={media.caption}
                                                          data-thumb={media.image}/>
                                            }
                                          })
                                        }
                                      </div>
                                  }
                                </div>
                              </div>
                            </>
                            : props.album.length > 1 && (props.sliderSearchBar != true || (Checkers.objChildExists(props, 'propertyJumbotronType') && props.propertyJumbotronType == Consts.JumbotronCarouselImage)) ?
                                <>
                                  <div className={"test-1 inner " + (fullHeight ? 'full-height' : "")}>

                                    {
                                        props.propertyJumbotronType != Consts.JumbotronGridImage &&
                                        <>
                                          <FeaturedImagesDesktop
                                              sliderThumbnails={props.sliderThumbnails}
                                              isDesktop={props.isDesktop}
                                              album={props.album}
                                              pageTitle={pageTitle}
                                              motto={props.motto}
                                              designMode={props.designMode}
                                              logoDecoration={props.logoDecoration}
                                              logoDecorationOriginWidth={props.logoDecorationOriginWidth}
                                              logoDecorationOriginHeight={props.logoDecorationOriginHeight}
                                              jumbotronAutoplay={props.jumbotronAutoplay}
                                              jumbotronTextAlign={props.jumbotronTextAlign}
                                              jumbotronTextColor={props.jumbotronTextColor}
                                              sliderTitleShadow={props.sliderTitleShadow}
                                              sliderTitleBold={props.sliderTitleBold}
                                              jumbotronOverlayOpacity={jumbotronOverlayOpacity}
                                              homeTitle={props.homeTitle}
                                              homeSubtitle={props.homeSubtitle}
                                              isProperty={property}
                                          />
                                        </>
                                    }

                                    {
                                        property && props.propertyJumbotronType == Consts.JumbotronGridImage &&
                                        <>
                                          <GridImagesDesktop
                                              sliderThumbnails={props.sliderThumbnails}
                                              isDesktop={props.isDesktop}
                                              album={props.album}
                                              motto={props.motto}
                                              designMode={props.designMode}
                                              logoDecoration={props.logoDecoration}
                                              logoDecorationOriginWidth={props.logoDecorationOriginWidth}
                                              logoDecorationOriginHeight={props.logoDecorationOriginHeight}
                                              jumbotronAutoplay={props.jumbotronAutoplay}
                                              jumbotronTextAlign={props.jumbotronTextAlign}
                                              jumbotronTextColor={props.jumbotronTextColor}
                                              sliderTitleShadow={props.sliderTitleShadow}
                                              sliderTitleBold={props.sliderTitleBold}
                                              jumbotronOverlayOpacity={jumbotronOverlayOpacity}
                                              homeTitle={props.homeTitle}
                                              homeSubtitle={props.homeSubtitle}
                                              isProperty={property}
                                              handleShow={handleShow}
                                              handleHide={handleHide}
                                              modalState={modalState}
                                              videoUrl={videoUrl}
                                          />
                                        </>
                                    }
                                  </div>
                                </>
                                : <></>
                    : <></>
                : <></>
          }
        </>
    )
  }

  const jumbotronContent = jumbotronContentHandler()

  return (
      jumbotronContent.props.children != false ?
          <>
            <section className={jumbotronClass + " jumbotron full-width full-vh"} data-bg-overlay={jumbotronOverlayOpacity > 0 ? 'black' : ''}
                     data-overlay-opacity={jumbotronOverlayOpacity} style={{height: jumbotronHeightConst}}>
              <>
                <h2 className={'hidden'}>Jumbotron</h2>
                {jumbotronContent}
                {/*<div className="bg-overlay" style={{backgroundColor: 'black', opacity: 0.4}}></div>*/}
              </>
            </section>
          </>
          :
          <></>
  )
}
