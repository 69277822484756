import React, {useEffect, useState} from 'react';

import FeaturedImages from './FeaturedImages';
import { FeaturedImagesThumbnails } from './FeaturedImagesThumbnails';
import SwipperWrapper from "../templates/properties/partials/SwipperWrapper";
import Checkers from "../../helpers/class/Checkers";
import Consts from "../../helpers/class/Consts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrid, faX} from "@fortawesome/pro-solid-svg-icons";
import CustomModal from "../CustomModal";
import ModalPropertyImages from "../templates/property/partials/modals/ModalPropertyImages";
import {useSelector} from "react-redux";
import {CloseButton, Modal} from "react-bootstrap";

export const GridImagesDesktop = ( props ) => {

  const isDesktop = useSelector( state => state['appSettings']['isDesktop']);

  const getGridArea = (key) => {
    if (key>5) {
      key = (key + 1) % 6;
    }
    // key++;
    // if(key>6){
    //   key = key - (6 * (key/6));
    // // }
    //
    // console.log('debughere', 'key', key, 'mod', positionCheck)

    if (key == 0)
      return 'boxA';
    else if (key == 1)
      return 'boxB';
    else if (key == 2)
      return 'boxC';
    else if (key == 3)
      return 'boxD';
    else if (key == 4)
      return 'boxE';

    return 'boxUnkown'
  }

  const [show, setShow] = useState(false)

  const closeHandler = () => {
    setShow(false)
  }

  // const urlParams = () => {
  //   if(Checkers.isValidString(props.videoUrl))
  //     return null
  //
  //   return new URLSearchParams(props.videoUrl);
  // }


  const getVideoParam = () => {
    if (!Checkers.isValidString(props.videoUrl) || props.videoUrl==0) {
      return null
    }

    if (!Checkers.isValidString(props.videoUrl)) {
      return null
    }

    let params = (new URL(props.videoUrl)).searchParams;
    let videoParam = params.get("v");

    return videoParam
  }

  const videoHandler = (event) => {
    setShow(true)

    event.preventDefault()
    event.stopPropagation()
  }

  return (
      <>
        <div className={'container d-flex m-t-25'} onClick={() => props.handleShow( 'property_photos_modal' )}>
          <div className="panel panel-transparent">
            <div className="panel-body p-0">
              <div className="property-custom-css-grid">
                {
                    props.album && props.album.map((item, index) => {
                      return (
                          index<5 &&
                          <>
                            <div className={getGridArea(index)}>
                              <div className="card social-card share full-width full-height" style={{overflow: "hidden", cursor: "pointer"}}>
                                <div className="card-content">
                                    <img alt="Social post" src={item.image} />
                                    {
                                      getVideoParam()!=null && index==0 ?
                                          <>
                                            <div
                                                onClick={videoHandler}
                                                className="video-overlay d-flex justify-content-center align-items-center"
                                                style={{
                                                  position: 'absolute',
                                                  top: 0,
                                                  left: 0,
                                                  width: '100%',
                                                  height: '100%',
                                                  backgroundColor: 'rgba(0,0,0,0.3)' }}>
                                              <div className="">
                                                <i className="fa-solid fa-play fa-2x text-white"></i>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          <>
                                          </>
                                    }
                                </div>
                              </div>
                            </div>
                          </>
                      )
                    })
                }
              </div>
            </div>
          </div>
        </div>

        <div className={'container'}>
          <div className={'container position-relative d-flex justify-content-end'}>
            {
                <>
                  <div className="position-absolute z-index-2 pe-3 pb-4" style={{ bottom: 0, right: 0 }}>
                    <button id={'jumbotron_show_modal'} type="button" className="btn btn-cons btn-lg text-master no-margin"
                            onClick={() => props.handleShow( 'property_photos_modal' )}
                    >
                      <FontAwesomeIcon icon={faGrid} className={'fs-18 m-r-10'} />Show photos
                    </button>
                    <CustomModal
                        modalName={'property_photos_modal'}
                        className="slide_right"
                        modalSize="lg"
                        closeModal={() => {
                          props.handleHide()
                        }}
                        show={props.modalState === 'property_photos_modal'}
                    >
                      <ModalPropertyImages all_images={props.album} handleHide={props.handleHide}/>
                    </CustomModal>
                    {
                      props.album.map( ( media, index ) => {
                        if ( index > 0 ) {
                          return <div key={media.image_id.toString()}
                                      className="hidden"
                                      data-fancybox="jumbotron-images"
                                      data-src={media.image}
                                      data-caption={media.caption}
                                      data-thumb={media.image}/>
                        }
                      })
                    }
                  </div>
                </>
            }
          </div>
        </div>


        {
          getVideoParam()!=null ?
              <>
                <Modal
                    className={'border-0 xxl transparent-content'}
                    size={'xl'}
                    show={show}
                    onHide={closeHandler}
                    animation={isDesktop ? true : false}
                    // onOverlayClick={clickOutSideHandler}
                >
                  <>
                    <iframe width="840" height="472" src={'https://www.youtube.com/embed/' + getVideoParam()}
                            style={{maxWidth: '100%'}}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    <button onClick={closeHandler} className={'btn btn-lg btn-primary btn-design-color w-auto close_button'}>
                      <FontAwesomeIcon icon={faX} className={'fs-18 m-r-10 b-r-1'} />
                      close
                    </button>
                  </>
                </Modal>
              </>
              :
              <>
              </>
        }
      </>
  )
}