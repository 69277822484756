import React, {useEffect, useState} from 'react';
import Image from 'next/image';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Lazy, Navigation, Thumbs } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/thumbs';
import 'swiper/css/free-mode'
import 'swiper/css/navigation';

import Motto from './Motto';

// Import Swiper styles
import 'swiper/css';

// install Swiper modules
SwiperCore.use([ Lazy, Navigation, Autoplay, Thumbs ]);

export const FeaturedImagesThumbnails = ( props ) => {

  const [ thumbsSwiper, setThumbsSwiper ] = useState( null );

  const autoplay = {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  };

  return (
    <>
      <Swiper
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        autoplay={ props.jumbotronAutoplay ? autoplay : false }
        className="mainSwiper"
      >
        {
          props.album !== undefined ?
            props.album !== null && props.album.length > 0 ?
              props.album.map( ( slide, key ) => (
                <SwiperSlide key={key}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <Image
                    priority={key == 0}
                    layout={'fill'}
                    // loading={ key == 0 ? 'eager' : 'lazy'}
                    sizes={'75vw'}
                    quality={'75'}
                    src={slide.image}
                    alt={'Slide-' + key}
                    placeholder={'blur'}
                    blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                  />
                  {
                    key === 0 && props.motto != null &&
                      <Motto
                        isDesktop={props.isDesktop}
                        motto={props.motto}
                        logoDecoration={props.logoDecoration}
                        logoDecorationOriginWidth={props.logoDecorationOriginWidth}
                        logoDecorationOriginHeight={props.logoDecorationOriginHeight}
                        jumbotronTextAlign={props.jumbotronTextAlign}
                        jumbotronTextColor={props.jumbotronTextColor}
                        sliderTitleShadow={props.sliderTitleShadow}
                        sliderTitleBold={props.sliderTitleBold}
                        homeTitle={props.homeTitle}
                        homeSubtitle={props.homeSubtitle}
                      />
                  }
                  {
                    props.jumbotronOverlayOpacity > 0 &&
                      <div className="bg-overlay z-index-1" style={{ backgroundColor: 'rgb(0, 0, 0)', opacity: props.jumbotronOverlayOpacity }}/>
                  }
                </SwiperSlide>
              ) )
              :''
            :''
        }
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        freeMode={true}
        spaceBetween={10}
        slidesPerView={4}
        watchSlidesProgress={true}
        className="thumbSwiper my-2"
      >
        {
          props.album !== undefined ?
            props.album !== null && props.album.length > 0 ?
              props.album.map( ( slide, key ) => (
                <SwiperSlide key={key}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <Image
                    layout={'fill'}
                    priority={key == 0}
                    // loading={ key == 0 ? 'eager' : 'lazy'}
                    sizes={'30vw'}
                    quality={'25'}
                    src={slide.image}
                    alt={'Slide-' + key}
                    placeholder={'blur'}
                    blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                  />
                </SwiperSlide>
              ) )
              :''
            :''
        }
      </Swiper>
    </>
  )
}
