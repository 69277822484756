import React, {useEffect} from 'react';

import FeaturedImages from './FeaturedImages';
import { FeaturedImagesThumbnails } from './FeaturedImagesThumbnails';
import SwipperWrapper from "../templates/properties/partials/SwipperWrapper";

export const FeaturedImagesDesktop = ( props ) => {

  const pageTitle = props.hasOwnProperty('pageTitle') ? props.pageTitle : ''

  return (
    <>
      {
        props.sliderThumbnails ?
          <FeaturedImagesThumbnails
            isDesktop={props.isDesktop}
            album={props.album}
            motto={props.motto}
            designMode={props.designMode}
            logoDecoration={props.logoDecoration}
            logoDecorationOriginWidth={props.logoDecorationOriginWidth}
            logoDecorationOriginHeight={props.logoDecorationOriginHeight}
            jumbotronAutoplay={props.jumbotronAutoplay}
            jumbotronTextAlign={props.jumbotronTextAlign}
            jumbotronTextColor={props.jumbotronTextColor}
            sliderTitleShadow={props.sliderTitleShadow}
            sliderTitleBold={props.sliderTitleBold}
            jumbotronOverlayOpacity={props.jumbotronOverlayOpacity}
            homeTitle={props.homeTitle}
            homeSubtitle={props.homeSubtitle}
          />
          :
            <SwipperWrapper
                key={'featured-image-swiper'}
                images={props.album}
                imagesToShow={6}
                galleryHeight={"100%"}
                wrapperClassName={''}
                enableAutoplay={props.jumbotronAutoplay}
                designMode={props.designMode}
                spaceBetween={0}
                customNavigationArrows={false}
                enableFancyBox={false}
                enableAlbumRightModal={props.property}
                jumbotronOverlayOpacity={props.jumbotronOverlayOpacity}
                motto={props.motto}
                firstSlideTitle={props.homeTitle}
                firstSlideSubtitle={props.homeSubtitle}
                logoDecoration={props.logoDecoration}
                logoDecorationOriginWidth={props.logoDecorationOriginWidth}
                logoDecorationOriginHeight={props.logoDecorationOriginHeight}
                jumbotronTextAlign={props.jumbotronTextAlign}
                jumbotronTextColor={props.jumbotronTextColor}
                sliderTitleShadow={props.sliderTitleShadow}
                sliderTitleBold={props.sliderTitleBold}
                pageTitle={pageTitle}
            />
      }
    </>
  )
}